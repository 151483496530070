import type { Category, Header } from "payload/generated-types"

import { type LoaderFunctionArgs } from "@remix-run/node"
import { json } from "@remix-run/node"
import { Link, NavLink, Outlet, useLoaderData } from "@remix-run/react"

import { Toaster } from "@/components/toaster"
import { ColorsCategory } from "@/lib/colors-category"
import { cn } from "@/lib/utils"

export const loader = async ({ context: { payload } }: LoaderFunctionArgs) => {
  const header = await payload.findGlobal({
    slug: "header"
  }) as Header

  const navItems = header.navItems?.map(({ id, link }) => ({
    id: id,
    target: link?.newTab ? "_blank" : undefined,
    color: link?.type === "reference" && link?.reference?.relationTo === "categories" ? (link?.reference?.value as Category).color : undefined,
    to: (link?.type === "reference" && link?.reference?.relationTo === "categories" ? `/${(link?.reference?.value as Category).slug}` : link?.url) as string,
    label: link?.type === "reference" && link?.reference?.relationTo === "categories" ? (link?.reference?.value as Category).title : link?.label,
  }))

  return json({
    header: {
      navItems
    }
  })
}

export default function Element() {
  const { header } = useLoaderData<typeof loader>()
  return (
    <div className="flex flex-col min-h-svh">
      <header className="sticky top-0 z-10 border-b border-gray-100 backdrop-blur-sm bg-white/95">
        <div className="container pt-4 flex justify-center items-end max-w-prose">
          <Link to="/">
            <img src="/logo.webp" alt="Bubble Geek" title="Bubble Geek" className="h-7 w-auto" width="185" height="28" />
          </Link>
        </div>
        <nav className="overflow-x-auto no-scrollbar">
          <ul className="container max-w-prose flex gap-4 font-medium whitespace-nowrap">
            {header.navItems?.map(({ id, to, label, target, color }) => (
              <li key={id} className="last:pr-4">
                <NavLink to={to} target={target} className={({ isActive }) => cn("block py-2 underline-offset-[12px] decoration-2 hover:underline", isActive && "underline", color && ColorsCategory[color])}>{label}</NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <main className="grow">
        <Outlet />
      </main>
      <footer className="bg-gray-50 mt-12">
        <div className="container">
          <div className="py-4 md:py-6">
            <Link to="/">
              <img src="/logo.webp" alt="Bubble Geek" title="Bubble Geek" className="h-7 w-auto" width="185" height="28" />
            </Link>
          </div>
          <div className="flex flex-col md:flex-row gap-4 justify-between md:items-center pb-4 text-sm">
            <p className="text-gray-600 md:ml-1">© Copyright 2024 BubbleGeek</p>
            <nav className="flex flex-col md:flex-row md:divide-x-2">
              <Link to="/politica-de-privacidade" className="my-0.5 md:px-2 text-indigo-500 hover:underline">
                Política de Privacidade
              </Link>
              <Link to="/termos-e-condicoes" className="my-0.5 md:px-2 text-indigo-500 hover:underline">
                Termos e Condições
              </Link>
              <Link to="/contato" className="my-0.5 md:px-2 text-indigo-500 hover:underline">
                Contato
              </Link>
            </nav>
          </div>
        </div>
      </footer>
      <Toaster />
    </div>
  )
}

